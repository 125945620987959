import React from "react";
import { graphql } from "gatsby";
import Gridcontainer from "../components/gridcontainer";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import { responsiveTitle0 } from "../components/typography.module.css";

import * as styles from "./categories.module.css";

export const query = graphql`
  query CategoriesPageQuery {
    categories: allSanityCategory(filter: {}, sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          icon {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
        }
      }
    }
  }
`;

const CategoriesPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const categoryNodes = data && data.categories && mapEdgesToNodes(data.categories);

  return (
    <Layout>
      <SEO title="Kategorien" />
      <Gridcontainer>
        <h1 className={responsiveTitle0}>Kategorien</h1>
        <div className={styles.categoryGrid}>
          {categoryNodes &&
            categoryNodes.length > 0 &&
            categoryNodes.map((node) => (
              <div key={node.id} className={styles.categoryContainer}>
                <a href={"/category/" + node.id} className={styles.categoryLink}>
                  <div className={styles.iconCenter}>
                    {node.icon && node.icon.asset && (
                      <img
                        src={imageUrlFor(buildImageObj(node.icon))
                          .ignoreImageParams()
                          .width(200)
                          .height(200)
                          .fit("clip")
                          .auto("format")
                          .url()}
                        alt={node.icon.alt}
                      />
                    )}
                  </div>
                  <div>{node.title}</div>
                </a>
              </div>
            ))}
        </div>
      </Gridcontainer>
    </Layout>
  );
};

export default CategoriesPage;
